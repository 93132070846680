<template>
  <div class="page-banners-add">
    <pro-form
      label-width="120px"
      label-suffix=":"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitButtonProps: {
          loading
        },
        submitText: '保存',
        resetText: '返回'
      }"
      @submit="submit"
      @reset="reset"
    ></pro-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'
import { localStore } from '@/utils/store'
import * as ConstantRouter from '@/constant/router'

export default {
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
      jsList: [],
      jsLoading: false,
      model: {
        model: undefined, // 模块 1首页 2校地合作 3校企合作
        tab: '',
        title: '',
        imgUrlFile: null,
        urlType: undefined, // 1 公众号链接 2内部文章
        url: '' // 跳转链接或文章id
      },
      fields: [
        {
          type: 'radio-group',
          formItemProps: {
            label: '模块',
            prop: 'model'
          },
          children: [
            {
              label: 1,
              text: '首页'
            },
            {
              label: 2,
              text: '校地合作'
            },
            {
              label: 3,
              text: '校企合作'
            }
          ]
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: 'tab',
            prop: 'tab'
          },
          children: [
            {
              label: 'banner',
              text: 'banner'
            },
            {
              label: 'card',
              text: 'card'
            }
          ]
        },
        {
          type: 'input',
          formItemProps: {
            label: '标题',
            prop: 'title'
          },
          style: {
            width: '360px'
          }
        },
        {
          formItemProps: {
            label: '图片',
            prop: 'imgUrlFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '跳转类型',
            prop: 'urlType'
          },
          children: [
            {
              label: 1,
              text: '公众号链接'
            },
            {
              label: 2,
              text: '内部文章'
            }
          ]
        },
        {
          type: 'input',
          formItemProps: {
            label: '跳转地址',
            prop: 'url'
          },
          style: {
            width: '360px'
          }
        }
      ],
      rules: {
        model: [{ required: true, message: '请输选择模块', trigger: 'change' }],
        tab: [{ required: true, message: '请输选择tab', trigger: 'change' }],
        title: [{ required: true, message: '请输入标题', trigger: 'change' }],
        urlType: [
          { required: true, message: '请选择跳转类型', trigger: 'change' }
        ],
        url: [{ required: true, message: '请输入地址', trigger: 'change' }],
        imgUrlFile: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    if (this.id) this.fetchDetail()
  },
  methods: {
    async fetchJsList(keyword) {
      this.jsLoading = true
      const [err, res] = await this.$serve.jsRecord.getPageList({
        data: {
          pageSize: 100,
          keyword
        }
      })
      this.jsLoading = false
      if (err) return
      this.jsList = res.records.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },
    async fetchJsListFocus() {
      if (!this.jsList.length) {
        this.fetchJsList('')
      }
    },
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.mpBanner.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.model = {
        model: +res.model,
        tab: res.tab,
        title: res.title,
        imgUrlFile: res.imgUrl
          ? {
              uid: uid(),
              status: 'success',
              url: res.imgUrl
            }
          : null,
        urlType: res.urlType,
        url: res.url
      }
    },
    submit({ imgUrlFile, ...data }) {
      const params = {
        ...data
      }
      if (imgUrlFile) {
        params.imgUrl = imgUrlFile.response?.data || imgUrlFile?.url
      }
      if (this.id) {
        this.update(params)
      } else {
        this.add(params)
      }
    },
    reset() {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_MINI_BANNERS
      })
    },
    async add(data) {
      this.loading = true
      const [err] = await this.$serve.mpBanner.add({
        data
      })
      this.loading = false
      if (err) return
      this.$message.success('轮播图添加成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_MINI_BANNERS
      })
    },
    async update(data) {
      this.loading = true
      const [err] = await this.$serve.mpBanner.update({
        data: {
          ...data,
          id: this.id
        }
      })
      this.loading = false
      if (err) return
      this.$message.success('轮播图修改成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_MINI_BANNERS
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
